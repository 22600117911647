import React from 'react'

import SEO from '../components/seo'
import { Container, Row, Column } from '../components/ui/layout/Grid'
import { ContentBox } from '../components/ui/layout/ContentBox'
import { TopTitle } from '../components/Hero/PageIntro'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container size="md">
      <Row
        css={`
          min-height: 65vh;
        `}
      >
        <Column>
          <ContentBox
            css={`
              text-align:center;
            `}
          >
            <TopTitle>Well this is embarassing</TopTitle>
          	<h1>404 Error: Not Found</h1>
    		    <p>It seems the page or link you&#39;re trying to find doesn&#39;t exist. Try hitting the back button to start this journey again.</p>
    	    </ContentBox>
        </Column>
      </Row>
    </Container>
  </>
)

export default NotFoundPage
